import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Depilacja woskiem Olsztyn | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/oferta/depilacja-woskiem/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Piercing = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_4)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Depilacja woskiem</h1>

        <p className="lead" style={{ maxWidth: '90%' }}>Depilacja kosmetyczna jest jednym z&nbsp;popularniejszych sposobów usuwania niechcianych włosów. Wiele&nbsp;osób decyduje się na tę metodę, ponieważ jest ona skuteczna i&nbsp;trwała. Depilacja za pomocą wosku pozwala na usunięcie całych włosów, co zapewnia długotrwały efekt i&nbsp;znacznie opóźnia ich odrost.</p>

        <p>Depilacja woskiem polega na usunięciu włosów za pomocą specjalnego wosku, który jest nałożony na skórę i&nbsp;później zerwany. Wosk oddziela włosy od skóry i&nbsp;usuwa je razem z&nbsp;cebulkami, co powoduje, że włosy odrastają wolniej i&nbsp;są słabsze. Wiele osób wybiera depilację woskiem ze względu na jej trwały efekt - włosy wolniej odrastają i&nbsp;są słabsze niż po innych metodach depilacji.</p>

        <p>Depilacja woskiem może być nieco bolesna, ale dzięki naszemu doświadczeniu i&nbsp;wiedzy, jesteśmy w stanie zapewnić naszym klientom jak najmniejszy dyskomfort podczas zabiegu. Warto pamiętać, że skóra po depilacji może być wrażliwa na dotyk i&nbsp;światło słoneczne, dlatego zalecamy, aby po zabiegu unikać sauny, basenu i&nbsp;opalania przez kilka godzin.</p>

        {/* <p>
          W
          {' '}
          <Link to="/o-gabinecie/" title="Gabinet Elżbieta - o nas">naszym gabinecie</Link>
          {' '}
          oferujemy różne rodzaje wosków do depilacji,
          {' '}
          <strong>w&nbsp;tym wosk syntetyczny</strong>
          ,
          {' '}
          <strong>roślinny</strong>
          {' '}
          i
          {' '}
          <strong>brazylijski</strong>
          . Każdy z nich ma swoje unikalne właściwości i&nbsp;zalety, dlatego zawsze dobieramy wosk indywidualnie do potrzeb naszych klientów. Na przykład, wosk brazylijski jest szczególnie skuteczny przy usuwaniu włosów z&nbsp;bardziej wrażliwych obszarów, takich jak bikini, ponieważ jest bardzo miękki i&nbsp;delikatny.
        </p> */}

        <div style={{ textAlign: 'center', margin: '60px 0' }}>
          <GatsbyImage
            image={getImage(data.depilacja_nog)}
            alt="Gabinet Elżbieta"
            loading="eager"
          />
        </div>

        <p>
          Jeśli chodzi o cenę, depilacja woskiem jest jednym z&nbsp;bardziej wydajnych sposobów na usuwanie niechcianych włosów. Cena zależy od wielkości obszaru do depilacji i rodzaju wosku, ale w&nbsp;naszym gabinecie staramy się zapewnić
          {' '}
          <Link to="/cennik/" title="Depilacja woskiem - sprawdź ceny">konkurencyjne ceny</Link>
          , aby nasze usługi były dostępne dla jak największej liczby klientów.
        </p>

        <p>
          Jeśli chcesz skorzystać z&nbsp;depilacji woskiem, zachęcamy do
          {' '}
          <Link to="/kontakt/" title="Gabinet Elżbieta - kontakt">odwiedzenia naszego gabinetu</Link>
          {' '}
          i skonsultowania się z naszymi specjalistami. Z&nbsp;przyjemnością doradzimy Ci, jakie rozwiązanie będzie najlepsze dla Ciebie i&nbsp;pomożemy Ci uzyskać gładką, lśniącą skórę.
        </p>

        <p>
          Do&nbsp;zobaczenia w naszym gabinecie!
        </p>

        {/* <p>Zabieg polega na nakładaniu ciepłego wosku na skórę. Następnie przykleja się pasek fizelinowy i&nbsp;odrywa się go bardzo szybkim, zdecydowanym ruchem.</p>
        <p>Efekt po zabiegu depilacji woskiem utrzymuje się od 4 do 60 dni.</p>
        <p>Regularne stosowanie depilacji woskiem osłabia cebulki włosa. Efekt jest natychmiastowy. Zabieg możemy stosować na całym ciele.</p> */}
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_4: file(relativePath: {regex: "/hero\/4.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
    depilacja_nog: file(relativePath: {regex: "/offer\/depilacja-woskiem\/depilacja-nog.webp/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 800
        )
      }
    }
  }
`;

export default Piercing;
